import MainContentContainer from "./components/main-content-container/MainContentContainer";

import "./App.css";
import "./i18n";

function App() {
  return <MainContentContainer />;
}

export default App;

import * as React from "react";
import { ButtonGroup, Container, ToggleButton } from "react-bootstrap";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  ToolkitContextType,
} from "react-bootstrap-table2-toolkit";
import { WithTranslation, withTranslation } from "react-i18next";
import { IPreviewJob } from "../../../shared-interfaces/dto/IPreviewJob";

const { SearchBar } = Search;

export interface IPreviewJobDesktopTableProps extends WithTranslation {
  jobs: Array<IPreviewJob>;
  showTomorrow: boolean;
  setShowTomorrow(value: boolean): void;
}

class PreviewJobDesktopTable extends React.Component<IPreviewJobDesktopTableProps> {
  private columns: Array<ColumnDescription<IPreviewJob>>;

  constructor(props: IPreviewJobDesktopTableProps) {
    super(props);

    const { t } = this.props;

    this.columns = [
      {
        dataField: "id",
        hidden: true,
        text: "",
      },
      {
        dataField: "startTimestamp",
        text: t("preview_job_table:date"),
        sort: true,
        formatter: (cell: any, _) => {
          return this.toDate(cell);
        },
      },
      {
        dataField: "startTime",
        text: t("preview_job_table:start_timestamp"),
        formatter: (_, row: any) => {
          return this.toTime(row.startTimestamp);
        },
      },
      {
        dataField: "pickupTimestamp",
        text: t("preview_job_table:pickup_timestamp"),
        formatter: (cell: any, _) => {
          return this.toTime(cell);
        },
      },
      {
        dataField: "appointmentTimestamp",
        text: t("preview_job_table:appointment_timestamp"),
        formatter: (cell: any, _) => {
          return this.toTime(cell);
        },
      },
      {
        dataField: "responseCode",
        text: t("preview_job_table:response_code"),
      },
      {
        dataField: "specialJobType",
        text: t("preview_job_table:special_job_type"),
      },
      {
        dataField: "sceneAddress",
        text: t("preview_job_table:scene_address"),
      },
      {
        dataField: "destinationAddress",
        text: t("preview_job_table:destination_address"),
      },
      {
        dataField: "vehicle",
        text: t("preview_job_table:preplanned"),
        sort: true,
        formatter: (cell: any, _) => {
          return cell.toUpperCase();
        },
      },
    ];
  }

  private toTime(timestamp: Date): string {
    if (!timestamp) {
      return "";
    }

    let time = timestamp.toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return time;
  }

  private toDate(timestamp: Date): string {
    return timestamp.toLocaleDateString(navigator.language);
  }

  private getTable(toolkitProps: ToolkitContextType): React.ReactNode {
    const { t } = this.props;

    if (this.props.jobs.length > 0) {
      return (
        <React.Fragment>
          <div className="my-2 float-end">
            <SearchBar
              {...toolkitProps.searchProps}
              placeholder={t("preview_job_table:search")}
            />
          </div>
          <div className="float-start overflow-break-words w-100">
            <BootstrapTable
              {...toolkitProps.baseProps}
              striped
              hover
              wrapperClasses="table-responsive"
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="float-start w-100 text-center m-4">
        {t("preview_job_table:no_jobs_available")}
      </div>
    );
  }

  public render() {
    const { t } = this.props;

    return (
      <Container>
        <ToolkitProvider
          bootstrap4={true}
          keyField="id"
          data={this.props.jobs}
          columns={this.columns}
          search
        >
          {(toolkitProps) => (
            <div>
              <div className="my-2 float-start">
                <ButtonGroup>
                  <ToggleButton
                    key="day-select-1"
                    id="day-select-1"
                    type="radio"
                    variant="outline-primary"
                    value="false"
                    checked={!this.props.showTomorrow}
                    onChange={() => this.props.setShowTomorrow(false)}
                  >
                    {t("preview_job_table:today")}
                  </ToggleButton>
                  <ToggleButton
                    id="day-select-2"
                    key="day-select-2"
                    type="radio"
                    variant="outline-primary"
                    value="true"
                    checked={this.props.showTomorrow}
                    onChange={() => this.props.setShowTomorrow(true)}
                  >
                    {t("preview_job_table:tomorrow")}
                  </ToggleButton>
                </ButtonGroup>
              </div>
              {this.getTable(toolkitProps)}
            </div>
          )}
        </ToolkitProvider>
      </Container>
    );
  }
}

export default withTranslation()(PreviewJobDesktopTable);

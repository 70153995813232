import { t } from "i18next";
import * as React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export interface INavigationBarProps extends WithTranslation {
  isAuthenticated: boolean;
  handleSignOut(): void;
}

class NavigationBar extends React.Component<INavigationBarProps> {
  private getNavigationItems() {
    const { t } = this.props;

    if (this.props.isAuthenticated) {
      return (
        <React.Fragment>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="fa fa-bars"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link eventKey="link-jobs" as={NavLink} to="jobs">
                {t("navigation_bar:planned_jobs")}
              </Nav.Link>
              <Nav.Link eventKey="link-vehicles" as={NavLink} to="vehicles">
                {t("navigation_bar:vehicles")}
              </Nav.Link>
              <Nav.Link eventKey="link-map" as={NavLink} to="map">
                {t("navigation_bar:map")}
              </Nav.Link>
              <Nav.Link
                eventKey="link-changePassword"
                as={NavLink}
                to="changePassword"
              >
                {t("navigation_bar:change_password")}
              </Nav.Link>
            </Nav>
            <Nav className="justify-content-end ms-auto">
              <Nav.Link onClick={() => this.props.handleSignOut()}>
                <span className="fas fa-sign-out-alt"></span>{" "}
                {t("navigation_bar:sign_out")}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </React.Fragment>
      );
    } else {
      return (
        <Nav className="justify-content-end ms-auto">
          <Nav.Link eventKey="link-login" as={NavLink} to="login">
            <span className="fas fa-sign-in-alt"></span>{" "}
            {t("navigation_bar:sign_in")}
          </Nav.Link>
        </Nav>
      );
    }
  }

  public render() {
    return (
      <Navbar
        bg="dark"
        variant="dark"
        expand="lg"
        sticky="top"
        collapseOnSelect={true}
      >
        <Container>
          <Navbar.Brand href="/">{t("navigation_bar:xispo")}</Navbar.Brand>
          {this.getNavigationItems()}
        </Container>
      </Navbar>
    );
  }
}

export default withTranslation()(NavigationBar);

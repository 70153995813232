import axios from "axios";
import * as React from "react";
import { IPrivateComponentProps } from "../../shared-interfaces/IPrivateComponentProps";

import { WithTranslation, withTranslation } from "react-i18next";
import { IVehicleInformation } from "../../shared-interfaces/dto/IVehicleInformation";
import { Badge } from "react-bootstrap";
import VehicleMobileTable from "./vehicle-mobile-table/VehicleMobileTable";
import VehicleDesktopTable from "./vehicle-desktop-table/VehicleDesktopTable";
import { Color } from "react-bootstrap/esm/types";

export interface IVehicleTableTableProps
  extends IPrivateComponentProps,
    WithTranslation {}

interface IVehicleTableStats {
  isMobile: boolean;
  searchString: string;
  vehicles: Array<IVehicleInformation>;
}

class VehicleTable extends React.Component<
  IVehicleTableTableProps,
  IVehicleTableStats
> {
  private refreshInterval?: NodeJS.Timeout;

  constructor(props: IVehicleTableTableProps) {
    super(props);

    this.state = {
      isMobile: window.innerWidth < 991,
      searchString: "",
      vehicles: [],
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.getVehicles();

    this.refreshInterval = setInterval(() => this.getVehicles(), 60000);
  }

  private handleWindowSizeChange = () => {
    const isMobile = window.innerWidth < 991;

    if (this.state.isMobile !== isMobile) {
      this.setState({ isMobile: isMobile });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);

    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  private getVehicles(): void {
    axios
      .get("/api/mapInformation/getVehicleInfo", { timeout: 5000 })
      .then((response) => {
        this.setState({
          vehicles: response.data as Array<IVehicleInformation>,
        });
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          this.props.handleSignOut();
        }
      });
  }

  private selectFontColorBasedOnBackground(hex: string): Color {
    const match = hex.match(/\w\w/g);

    if (match) {
      const [red, green, blue] = match.map((x) => parseInt(x, 16));

      const threshold = red * 0.299 + green * 0.587 + blue * 0.114;

      if (threshold > 173) {
        return "dark";
      }
    }

    return "light";
  }

  private getStatusBadge(
    statusText: string,
    hexColor: string
  ): React.ReactNode {
    const fontColor = this.selectFontColorBasedOnBackground(hexColor);

    return (
      <Badge
        text={fontColor}
        bg="custom"
        style={{
          backgroundColor: hexColor,
        }}
      >
        {statusText}
      </Badge>
    );
  }

  public render() {
    const { t } = this.props;

    if (this.state.vehicles.length < 1) {
      return (
        <div className="text-center m-4">
          {t("vehicle_table:no_vehicles_available")}
        </div>
      );
    }

    if (this.state.isMobile) {
      return (
        <VehicleMobileTable
          vehicles={this.state.vehicles}
          getStatusBadge={(t, c) => this.getStatusBadge(t, c)}
        />
      );
    } else {
      return (
        <VehicleDesktopTable
          vehicles={this.state.vehicles}
          getStatusBadge={(t, c) => this.getStatusBadge(t, c)}
        />
      );
    }
  }
}

export default withTranslation()(VehicleTable);

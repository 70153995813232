import axios from "axios";
import * as React from "react";
import { IPreviewJob } from "../../shared-interfaces/dto/IPreviewJob";
import { IPrivateComponentProps } from "../../shared-interfaces/IPrivateComponentProps";

import "./PreviewJobTable.css";
import { WithTranslation, withTranslation } from "react-i18next";
import PreviewJobDesktopTable from "./preview-job-desktop-table/PreviewJobDesktopTable";
import PreviewJobMobileTable from "./preview-job-mobile-table/PreviewJobMobileTable";

export interface IPreviewJobTableProps
  extends IPrivateComponentProps,
    WithTranslation {}

interface IPreviewJobTableStats {
  isMobile: boolean;
  searchString: string;
  jobs: Array<IPreviewJob>;
  showTomorrow: boolean;
}

class PreviewJobTable extends React.Component<
  IPreviewJobTableProps,
  IPreviewJobTableStats
> {
  private refreshInterval?: NodeJS.Timeout;

  constructor(props: IPreviewJobTableProps) {
    super(props);

    this.state = {
      isMobile: window.innerWidth < 991,
      searchString: "",
      jobs: [],
      showTomorrow: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.getJobs();

    this.refreshInterval = setInterval(() => this.getJobs(), 60000);
  }

  private handleWindowSizeChange = () => {
    const isMobile = window.innerWidth < 991;

    if (this.state.isMobile !== isMobile) {
      this.setState({ isMobile: isMobile });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);

    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  private getJobs(): void {
    axios
      .get("/api/jobs", { timeout: 5000 })
      .then((response) => {
        const adjustedData = (response.data as Array<IPreviewJob>).map((x) => {
          x.startTimestamp = new Date(Date.parse(x.startTimestamp + "Z"));
          x.appointmentTimestamp = new Date(
            Date.parse(x.appointmentTimestamp + "Z")
          );
          x.pickupTimestamp = new Date(Date.parse(x.pickupTimestamp + "Z"));

          return x;
        });

        this.setState({ jobs: adjustedData });
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          this.props.handleSignOut();
        }
      });
  }

  private getFilteredJobsByTodayOrTomorrow(): Array<IPreviewJob> {
    const filterDate = new Date();

    if (this.state.showTomorrow) {
      filterDate.setDate(filterDate.getDate() + 1);
    }

    return this.state.jobs.filter(
      (x) => x.startTimestamp.getDate() === filterDate.getDate()
    );
  }

  private setShowTomorrow(value: boolean): void {
    this.setState({ showTomorrow: value });
  }

  public render() {
    if (this.state.isMobile) {
      return (
        <PreviewJobMobileTable
          jobs={this.getFilteredJobsByTodayOrTomorrow()}
          setShowTomorrow={(v) => this.setShowTomorrow(v)}
          showTomorrow={this.state.showTomorrow}
        />
      );
    } else {
      return (
        <PreviewJobDesktopTable
          jobs={this.getFilteredJobsByTodayOrTomorrow()}
          setShowTomorrow={(v) => this.setShowTomorrow(v)}
          showTomorrow={this.state.showTomorrow}
        />
      );
    }
  }
}

export default withTranslation()(PreviewJobTable);

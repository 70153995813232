import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en/translations.json";
import de from "./locales/de/translations.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: en,
  de: de,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en", // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

import * as React from "react";
import { Container, Table } from "react-bootstrap";
import BootstrapTable, {
  ColumnDescription,
  ExpandRowProps,
} from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { withTranslation, WithTranslation } from "react-i18next";
import { IVehicleInformation } from "../../../shared-interfaces/dto/IVehicleInformation";

import "./VehicleDesktopTable.css";

const { SearchBar } = Search;

export interface IVehicleDesktopTableProps extends WithTranslation {
  vehicles: Array<IVehicleInformation>;
  getStatusBadge(statusText: string, hexColor: string): React.ReactNode;
}

class VehicleDesktopTable extends React.Component<IVehicleDesktopTableProps> {
  private columns: Array<ColumnDescription<IVehicleInformation>>;

  constructor(props: IVehicleDesktopTableProps) {
    super(props);

    const { t } = this.props;

    this.columns = [
      {
        dataField: "id",
        hidden: true,
        text: "",
      },
      {
        dataField: "callSign",
        text: t("vehicle_table:call_sign"),
        sort: true,
        formatter: (cell: string, _) => {
          return cell.toUpperCase();
        },
      },
      {
        dataField: "dispositionStatusText",
        text: t("vehicle_table:disposition_status"),
        formatter: (cell: any, row: any) => {
          return (
            <div>
              {this.props.getStatusBadge(cell, row.dispositionStatusColor)}
            </div>
          );
        },
      },
    ];
  }

  private getExpansionConfig(): ExpandRowProps<IVehicleInformation, any> {
    const vehicleIdsWithNoJobs = this.props.vehicles
      .filter((x) => x.jobs.length === 0)
      .map((x) => x.id);

    const { t } = this.props;

    const config: ExpandRowProps<IVehicleInformation, any> = {
      renderer: (row: IVehicleInformation) => {
        if (row.jobs && row.jobs.length > 0) {
          return (
            <Table
              size="sm"
              borderless={true}
              hover={false}
              style={{ marginBottom: 0 }}
            >
              <thead>
                <tr>
                  <th>{t("vehicle_table:response_code")}</th>
                  <th>{t("vehicle_table:special_job_type")}</th>
                  <th>{t("vehicle_table:scene_or_destination_address")}</th>
                  <th>{t("vehicle_table:disposition_status")}</th>
                </tr>
              </thead>
              <tbody>
                {row.jobs.map((x) => {
                  let placeToGo = x.sceneAddress;

                  if (x.dispositionStatus === 7 || x.dispositionStatus === 8) {
                    placeToGo = x.destinationAddress;
                  }

                  return (
                    <tr key={x.id}>
                      <td>{x.responseCode}</td>
                      <td>{x.specialJobType}</td>
                      <td>{placeToGo}</td>
                      <td>
                        {this.props.getStatusBadge(
                          x.dispositionStatusText,
                          x.dispositionStatusColor
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          );
        }

        return <div></div>;
      },
      nonExpandable: vehicleIdsWithNoJobs,
      onlyOneExpanding: true,
    };

    return config;
  }

  private getRowStyle(row: IVehicleInformation, rowIndex: number): string {
    if (row.jobs.length > 0) {
      return "clickable-row";
    }

    return "";
  }

  public render() {
    const { t } = this.props;

    return (
      <Container>
        <ToolkitProvider
          bootstrap4={true}
          keyField="id"
          data={this.props.vehicles}
          columns={this.columns}
          search
        >
          {(props) => (
            <div>
              <div className="my-2 float-end">
                <SearchBar
                  {...props.searchProps}
                  placeholder={t("vehicle_table:search")}
                />
              </div>
              <div className="float-start overflow-break-words w-100">
                <BootstrapTable
                  expandRow={this.getExpansionConfig()}
                  rowClasses={this.getRowStyle}
                  {...props.baseProps}
                  striped
                  hover
                  wrapperClasses="table-responsive"
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </Container>
    );
  }
}

export default withTranslation()(VehicleDesktopTable);

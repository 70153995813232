import axios from "axios";
import * as React from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { Navigate } from "react-router";
import { IAnonComponentProps } from "../../shared-interfaces/IAnonComponentProps";
import MessageDisplay, { MessageType } from "../message-display/MessageDisplay";

export interface ILoginFormProps extends IAnonComponentProps, WithTranslation {}

interface ILoginFormState {
  redirectToReferrer: boolean;
  serviceNumber: string;
  password: string;
  isLoading: boolean;
  message?: string;
  messageType?: MessageType;
}

class LoginForm extends React.Component<ILoginFormProps, ILoginFormState> {
  private passwordInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: ILoginFormProps) {
    super(props);

    this.passwordInputRef = React.createRef();

    this.state = {
      redirectToReferrer: false,
      serviceNumber: "",
      password: "",
      isLoading: false,
      messageType: MessageType.Danger,
    };
  }

  private onServiceNumberChanged(event: any): void {
    this.setState({ serviceNumber: event.target.value });
  }

  private onPasswordChanged(event: any): void {
    this.setState({ password: event.target.value });
  }

  private handleSubmit(event: any): void {
    this.setState({ message: undefined, isLoading: true });

    const { serviceNumber, password } = this.state;
    const { t } = this.props;

    axios
      .post(
        "/api/authentication/login",
        {
          username: serviceNumber,
          password: password,
        },
        { timeout: 5000 }
      )
      .then((_) => {
        this.setState({ redirectToReferrer: true, isLoading: true });
        this.props.handleUserAuthenticated();
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          this.setState({
            message: t("login_form:service_number_or_password_incorrect"),
          });
        } else {
          this.setState({ message: t("login_form:error_occurred") });
        }

        this.setState({ password: "", isLoading: false });
        this.passwordInputRef.current?.focus();
      });

    event.preventDefault();
  }

  private getButtonOrSpinner(): React.ReactNode {
    const { t } = this.props;

    if (this.state.isLoading) {
      return (
        <Container className="mt-4">
          <Row className="justify-content-md-center">
            <Col md="auto">
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <div className="d-grid gap-2">
        <Button
          type="submit"
          variant="primary"
          size="lg"
          className="text-uppercase"
        >
          {t("login_form:sign_in")}
        </Button>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { redirectToReferrer } = this.state;
    const navigationGoal: string =
      (this.props.location?.state as any | null)?.from?.pathname || "/";

    if (redirectToReferrer) {
      return <Navigate to={navigationGoal} />;
    }

    return (
      <Container>
        <Row>
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <MessageDisplay
              message={this.state.message}
              type={this.state.messageType}
            />

            <Card className="card-action my-5">
              <Card.Body>
                <h5 className="card-title text-center">
                  {t("login_form:sign_in")}
                </h5>
                <form
                  className="form-action-content"
                  onSubmit={(e: any) => this.handleSubmit(e)}
                >
                  <div className="form-label-group">
                    <input
                      type="text"
                      id="inputServiceNumber"
                      name="serviceNumber"
                      className="form-control"
                      value={this.state.serviceNumber}
                      onChange={(e: any) => this.onServiceNumberChanged(e)}
                      placeholder={t("login_form:service_number")}
                      required
                      autoFocus
                    />
                    <label htmlFor="inputServiceNumber">
                      {t("login_form:service_number")}
                    </label>
                  </div>

                  <div className="form-label-group">
                    <input
                      type="password"
                      id="inputPassword"
                      name="password"
                      ref={this.passwordInputRef}
                      className="form-control"
                      value={this.state.password}
                      onChange={(e: any) => this.onPasswordChanged(e)}
                      placeholder={t("login_form:password")}
                      required
                    />
                    <label htmlFor="inputPassword">
                      {t("login_form:password")}
                    </label>
                  </div>

                  {this.getButtonOrSpinner()}
                </form>
              </Card.Body>
            </Card>
          </div>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(LoginForm);

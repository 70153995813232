import axios from "axios";
import * as React from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { IPrivateComponentProps } from "../../shared-interfaces/IPrivateComponentProps";
import MessageDisplay, { MessageType } from "../message-display/MessageDisplay";

export interface IPasswordChangeFormProps
  extends IPrivateComponentProps,
    WithTranslation {}

interface IPasswordChangeFormState {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
  isLoading: boolean;
  message?: string;
  messageType?: MessageType;
}

class PasswordChangeForm extends React.Component<
  IPasswordChangeFormProps,
  IPasswordChangeFormState
> {
  private oldPasswordInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: IPasswordChangeFormProps) {
    super(props);

    this.oldPasswordInputRef = React.createRef();

    this.state = {
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
      isLoading: false,
    };
  }

  private onOldPasswordChanged(event: any): void {
    this.setState({ oldPassword: event.target.value });
  }

  private onNewPasswordChanged(event: any): void {
    this.setState({ newPassword: event.target.value });
  }

  private onNewPasswordRepeatChanged(event: any): void {
    this.setState({ newPasswordRepeat: event.target.value });
  }

  private checkPasswordLength(): boolean {
    return (
      this.state.newPassword !== undefined && this.state.newPassword.length >= 8
    );
  }

  private checkPasswordStructure(): boolean {
    const regex = new RegExp("(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])");

    if (this.state.newPassword) {
      return regex.test(this.state.newPassword);
    }
    return false;
  }

  private checkPasswordMatch(): boolean {
    return (
      this.state.newPassword !== undefined &&
      this.state.newPassword !== "" &&
      this.state.newPassword === this.state.newPasswordRepeat
    );
  }

  private canSubmit(): boolean {
    return (
      this.checkPasswordLength() &&
      this.checkPasswordStructure() &&
      this.checkPasswordMatch()
    );
  }

  private getIcon(value: boolean): React.ReactNode {
    if (value) {
      return <i className="fas fa-check-circle" />;
    }

    return <i className="fas fa-times-circle" />;
  }

  private getButtonOrSpinner(): React.ReactNode {
    const { t } = this.props;

    if (this.state.isLoading) {
      return (
        <Container className="mt-4">
          <Row className="justify-content-md-center">
            <Col md="auto">
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <div className="d-grid gap-2">
        <Button
          type="submit"
          variant="primary"
          size="lg"
          disabled={!this.canSubmit()}
          className="text-uppercase"
        >
          {t("password_change_form:change_password")}
        </Button>
      </div>
    );
  }

  private handleSubmit(event: any): void {
    this.setState({ message: undefined, isLoading: true });

    const { t } = this.props;
    const { oldPassword, newPassword } = this.state;

    axios
      .put(
        "/api/users/changePassword",
        {
          oldPassword: oldPassword,
          newPassword: newPassword,
        },
        { timeout: 5000 }
      )
      .then((_) => {
        this.setState({
          message: t("password_change_form:password_change_successful"),
          messageType: MessageType.Success,
        })
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            this.setState({
              message: t("password_change_form:old_password_incorrect"),
              messageType: MessageType.Danger,
            });
          } else if (
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            this.props.handleSignOut();
          }
        } else {
          this.setState({
            message: t("password_change_form:error_occurred"),
            messageType: MessageType.Danger,
          });
        }
      })
      .finally(() => {
        this.setState({
          oldPassword: "",
          newPassword: "",
          newPasswordRepeat: "",
          isLoading: false,
        }, () => window.scrollTo({top: 0}));

        if (this.oldPasswordInputRef) {
          this.oldPasswordInputRef.current?.focus();
        }
      });

    event.preventDefault();
  }

  public render() {
    const { t } = this.props;

    return (
      <Container>
        <Row>
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <MessageDisplay
              message={this.state.message}
              type={this.state.messageType}
            />

            <Card className="card-action my-5">
              <Card.Body>
                <h5 className="card-title text-center">
                  {t("password_change_form:change_password")}
                </h5>
                <form
                  className="form-action-content"
                  onSubmit={(e: any) => this.handleSubmit(e)}
                >
                  <div className="form-label-group">
                    <input
                      type="password"
                      id="inputOldPassword"
                      ref={this.oldPasswordInputRef}
                      name="oldPassword"
                      className="form-control"
                      placeholder={t("password_change_form:old_password")}
                      onChange={(e: any) => this.onOldPasswordChanged(e)}
                      value={this.state.oldPassword}
                      disabled={this.state.isLoading}
                      required
                      autoFocus
                    />
                    <label htmlFor="inputOldPassword">
                      {t("password_change_form:old_password")}
                    </label>
                  </div>

                  <div className="form-label-group">
                    <input
                      type="password"
                      id="inputNewPassword"
                      name="newPassword"
                      className="form-control"
                      placeholder={t("password_change_form:new_password")}
                      onChange={(e: any) => this.onNewPasswordChanged(e)}
                      value={this.state.newPassword}
                      disabled={this.state.isLoading}
                      required
                    />
                    <label htmlFor="inputNewPassword">
                      {t("password_change_form:new_password")}
                    </label>
                  </div>

                  <div className="form-label-group">
                    <input
                      type="password"
                      id="inputNewPasswordRepeat"
                      name="newPasswordRepeat"
                      className="form-control"
                      placeholder={t(
                        "password_change_form:repeat_new_password"
                      )}
                      onChange={(e: any) => this.onNewPasswordRepeatChanged(e)}
                      value={this.state.newPasswordRepeat}
                      disabled={this.state.isLoading}
                      required
                    />
                    <label htmlFor="inputNewPasswordRepeat">
                      {t("password_change_form:repeat_new_password")}
                    </label>
                  </div>

                  <Container className="my-4">
                    <Row>
                      <Col md="auto">
                        {this.getIcon(this.checkPasswordLength())}
                      </Col>
                      <Col>
                        {t("password_change_form:password_correct_characters")}
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md="auto">
                        {this.getIcon(this.checkPasswordStructure())}
                      </Col>
                      <Col>
                        {t("password_change_form:password_correct_regex")}
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md="auto">
                        {this.getIcon(this.checkPasswordMatch())}
                      </Col>
                      <Col>
                        {t("password_change_form:password_correct_repetition")}
                      </Col>
                    </Row>
                  </Container>

                  {this.getButtonOrSpinner()}
                </form>
              </Card.Body>
            </Card>
          </div>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(PasswordChangeForm);

import axios from "axios";
import * as React from "react";
import { MapContainer, Marker, TileLayer, ZoomControl } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { withTranslation, WithTranslation } from "react-i18next";
import { IVehicleInformation } from "../../shared-interfaces/dto/IVehicleInformation";
import { IPrivateComponentProps } from "../../shared-interfaces/IPrivateComponentProps";

import L, { LatLng } from "leaflet";
import "./VehicleMap.css";

export interface IVehicleMapProps
  extends IPrivateComponentProps,
    WithTranslation {}

interface IVehicleMapState {
  isMobile: boolean;
  vehicles: Array<IVehicleInformation>;
}

class VehicleMap extends React.Component<IVehicleMapProps, IVehicleMapState> {
  private refreshInterval?: NodeJS.Timeout;

  constructor(props: IVehicleMapProps) {
    super(props);

    this.state = {
      isMobile: window.innerWidth < 991,
      vehicles: [],
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.getVehicles();

    this.refreshInterval = setInterval(() => this.getVehicles(), 60000);
  }

  private handleWindowSizeChange = () => {
    const isMobile = window.innerWidth < 991;

    if (this.state.isMobile !== isMobile) {
      this.setState({ isMobile: isMobile });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);

    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  private getVehicles(): void {
    axios
      .get("/api/mapInformation/getVehicleInfo", { timeout: 5000 })
      .then((response) => {
        this.setState({
          vehicles: response.data as Array<IVehicleInformation>,
        });
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          this.props.handleSignOut();
        }
      });
  }

  private getMarkerIcon(name: string, statusColor: string): L.DivIcon {
    return L.divIcon({
      iconSize: undefined,
      html:
        '<div class="map-label">' +
        '<div class="map-label-content" ' +
        'style="background-color:' +
        statusColor +
        '">' +
        name +
        "</div>" +
        '<div class="map-label-arrow"></div>' +
        "</div>",
    });
  }

  private getMarkers(): React.ReactNode {
    if (this.state.vehicles && this.state.vehicles.length > 0) {
      return this.state.vehicles.map((x) => {
        const position = new LatLng(x.latitude, x.longitude);

        return (
          <Marker
            key={x.id}
            position={position}
            icon={this.getMarkerIcon(
              x.callSign.substring(3),
              x.dispositionStatusColor
            )}
          ></Marker>
        );
      });
    }

    return null;
  }

  public render() {
    return (
      <MapContainer
        center={[47.01, 11.387]}
        zoom={8}
        className="flex-grow-1"
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {!this.state.isMobile ? <ZoomControl /> : null}
        <MarkerClusterGroup maxClusterRadius={40}>
          {this.getMarkers()}
        </MarkerClusterGroup>
      </MapContainer>
    );
  }
}

export default withTranslation()(VehicleMap);

import * as React from "react";
import {
  Accordion,
  ButtonGroup,
  Col,
  Container,
  Row,
  ToggleButton,
} from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { IPreviewJob } from "../../../shared-interfaces/dto/IPreviewJob";

export interface IPreviewJobMobileTableProps extends WithTranslation {
  jobs: Array<IPreviewJob>;
  showTomorrow: boolean;
  setShowTomorrow(value: boolean): void;
}

interface IPreviewJobMobileTableState {
  searchString: string;
}

class PreviewJobMobileTable extends React.Component<
  IPreviewJobMobileTableProps,
  IPreviewJobMobileTableState
> {
  constructor(props: IPreviewJobMobileTableProps) {
    super(props);

    this.state = {
      searchString: "",
    };
  }

  private onSearchStringChanged(event: any): void {
    let searchString: string | undefined = event.target.value;

    if (searchString || searchString === "") {
      this.setState({ searchString: searchString });
    }
  }

  private filterItems(): Array<IPreviewJob> {
    return this.props.jobs.filter((x) => {
      if (this.state.searchString) {
        let searchString =
          this.toTime(x.startTimestamp).toLowerCase() +
          "," +
          this.toDate(x.startTimestamp) +
          "," +
          this.toTime(x.pickupTimestamp) +
          "," +
          this.toTime(x.appointmentTimestamp) +
          ",";

        if (x.specialJobType) {
          searchString += x.specialJobType.toLowerCase() + ",";
        }

        if (x.sceneAddress) {
          searchString += x.sceneAddress.toLowerCase() + ",";
        }

        if (x.destinationAddress) {
          searchString += x.destinationAddress.toLowerCase() + ",";
        }

        if (x.vehicle) {
          searchString += x.vehicle.toLowerCase();
        }

        if (searchString.indexOf(this.state.searchString) === -1) {
          return false;
        }
      }

      return true;
    });
  }

  private toTime(timestamp: Date): string {
    if (!timestamp) {
      return "";
    }

    let time = timestamp.toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return (
      timestamp.toLocaleDateString(navigator.language, {
        weekday: "short",
        timeZone: "UTC",
      }) +
      ", " +
      time
    );
  }

  private toDate(timestamp: Date): string {
    return timestamp.toLocaleDateString(navigator.language);
  }

  private getVehicleCol(vehicle: string | undefined): React.ReactNode {
    if (vehicle) {
      return (
        <React.Fragment>
          <i className="fas fa-ambulance" /> {vehicle.toUpperCase()}
        </React.Fragment>
      );
    }

    return null;
  }

  private getItems(): React.ReactNode {
    const { t } = this.props;

    return this.filterItems().map((x) => {
      return (
        <Accordion.Item key={x.id} eventKey={x.id.toString()}>
          <Accordion.Header>
            <Container>
              <Row>
                <Col>
                  <i className="far fa-clock" />
                  <span className="ms-2">{this.toTime(x.startTimestamp)}</span>
                </Col>
                <Col>{this.getVehicleCol(x.vehicle)}</Col>
              </Row>
              <Row className="mt-2">
                <Col>{x.specialJobType}</Col>
              </Row>
            </Container>
          </Accordion.Header>
          <Accordion.Body>
            <Container>
              <Row>
                <Col>{t("preview_job_table:date")}</Col>
                <Col>{this.toDate(x.startTimestamp)}</Col>
              </Row>
              <Row className="mt-1">
                <Col>{t("preview_job_table:pickup_timestamp")}</Col>
                <Col>{this.toTime(x.pickupTimestamp)}</Col>
              </Row>
              <Row className="mt-1">
                <Col>{t("preview_job_table:appointment_timestamp")}</Col>
                <Col>{this.toTime(x.appointmentTimestamp)}</Col>
              </Row>
              <Row className="mt-1">
                <Col>{t("preview_job_table:response_code")}</Col>
                <Col>{x.responseCode}</Col>
              </Row>
              <Row className="mt-1">
                <Col>{t("preview_job_table:scene_address")}</Col>
                <Col>{x.sceneAddress}</Col>
              </Row>
              <Row className="mt-1">
                <Col>{t("preview_job_table:destination_address")}</Col>
                <Col>{x.destinationAddress}</Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      );
    });
  }

  private getSearchBar(): React.ReactNode {
    const { t } = this.props;

    if (this.props.jobs.length > 0) {
      return (
        <label
          className="search-label"
          htmlFor="search-bar-0"
          style={{ width: "100%" }}
        >
          <span className="sr-only">{t("preview_job_table:search")}</span>
          <input
            id="search-bar-0"
            type="text"
            autoComplete="off"
            name="searchString"
            value={this.state.searchString}
            onChange={(e: any) => this.onSearchStringChanged(e)}
            aria-label={t("preview_job_table:search")}
            className="form-control"
            placeholder={t("preview_job_table:search")}
          />
        </label>
      );
    }

    return null;
  }

  public render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Container fluid={true} className="mb-2 mt-3">
          <ButtonGroup className="mb-3 w-100">
            <ToggleButton
              key="day-select-1"
              id="day-select-1"
              type="radio"
              variant="outline-primary"
              value="false"
              checked={!this.props.showTomorrow}
              onChange={() => this.props.setShowTomorrow(false)}
            >
              {t("preview_job_table:today")}
            </ToggleButton>
            <ToggleButton
              id="day-select-2"
              key="day-select-2"
              type="radio"
              variant="outline-primary"
              value="true"
              checked={this.props.showTomorrow}
              onChange={() => this.props.setShowTomorrow(true)}
            >
              {t("preview_job_table:tomorrow")}
            </ToggleButton>
          </ButtonGroup>

          {this.getSearchBar()}
        </Container>

        {this.props.jobs.length > 0 ? (
          <Accordion>{this.getItems()}</Accordion>
        ) : (
          <div className="text-center m-4">
            {t("preview_job_table:no_jobs_available")}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(PreviewJobMobileTable);

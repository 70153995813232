import * as React from "react";
import { Navigate, useLocation } from "react-router";
import { IAnonComponentProps } from "../../../shared-interfaces/IAnonComponentProps";

interface IAnonRouteProps {
  component: React.ComponentType<any>;
  isAuthenticated: boolean;
  handleUserAuthenticated(): void;
}

const AnonRoute: React.FunctionComponent<IAnonRouteProps> = (props) => {
  const location = useLocation();

  if (props.isAuthenticated) {
    return <Navigate to="/" />;
  }

  const newProps: IAnonComponentProps = {
    isAuthenticated: props.isAuthenticated,
    handleUserAuthenticated: props.handleUserAuthenticated,
    location: location,
  };

  return React.createElement(props.component, newProps);
};

export default AnonRoute;

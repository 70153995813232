import * as React from "react";
import { Alert } from "react-bootstrap";

interface IMessageDisplayProps {
  message?: string;
  type?: MessageType;
}

const MessageDisplay: React.FunctionComponent<IMessageDisplayProps> = (
  props
) => {
  if (props.message && props.type) {
    return (
      <Alert
        variant={props.type}
        className="text-center mt-4"
        style={{ marginBottom: 0 }}
      >
        {props.message}
      </Alert>
    );
  }

  return null;
};

export enum MessageType {
  Primary = "primary",
  Secondary = "secondary",
  Success = "success",
  Danger = "danger",
  Warning = "warning",
  Info = "info",
  Light = "light",
  Dark = "dark",
}

export default MessageDisplay;

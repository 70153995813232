import * as React from "react";
import { Navigate, useLocation } from "react-router";
import { IPrivateComponentProps } from "../../../shared-interfaces/IPrivateComponentProps";

interface IPrivateRouteProps {
  component: React.ComponentType<any>;
  isAuthenticated: boolean;
  handleSignOut(): void;
}

const PrivateRoute: React.FunctionComponent<IPrivateRouteProps> = (props) => {
  const location = useLocation();

  if (props.isAuthenticated) {
    const newProps: IPrivateComponentProps = {
      isAuthenticated: props.isAuthenticated,
      handleSignOut: props.handleSignOut,
      location: location,
    };

    return React.createElement(props.component, newProps);
  }

  return (
    <Navigate
      to={{
        pathname: "/login",
      }}
      state={{ from: location }}
    />
  );
};

export default PrivateRoute;

import * as React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { IJobInformation } from "../../../shared-interfaces/dto/IJobInformation";
import { IVehicleInformation } from "../../../shared-interfaces/dto/IVehicleInformation";

export interface IVehicleMobileTableProps extends WithTranslation {
  vehicles: Array<IVehicleInformation>;
  getStatusBadge(statusText: string, hexColor: string): React.ReactNode;
}

interface IVehicleMobileTableState {
  searchString: string;
}

class VehicleMobileTable extends React.Component<
  IVehicleMobileTableProps,
  IVehicleMobileTableState
> {
  constructor(props: IVehicleMobileTableProps) {
    super(props);

    this.state = {
      searchString: "",
    };
  }

  private onSearchStringChanged(event: any): void {
    let searchString: string | undefined = event.target.value;

    if (searchString || searchString === "") {
      this.setState({ searchString: searchString });
    }
  }

  private filterItems(): Array<IVehicleInformation> {
    return this.props.vehicles.filter((x) => {
      if (this.state.searchString) {
        const searchString =
          x.callSign.toLowerCase() +
          "," +
          x.dispositionStatusText.toLowerCase();

        if (searchString.indexOf(this.state.searchString) === -1) {
          return false;
        }
      }

      return true;
    });
  }

  private getExtendedJobs(jobs: Array<IJobInformation>): React.ReactNode {
    const { t } = this.props;

    if (jobs && jobs.length > 0) {
      return jobs.map((x, index) => {
        const lastItemIndex = jobs.length - 1;

        let placeToGo = x.sceneAddress;

        if (x.dispositionStatus === 7 || x.dispositionStatus === 8) {
          placeToGo = x.destinationAddress;
        }

        return (
          <React.Fragment key={x.id}>
            <Row>
              <Col>{x.responseCode}</Col>
              <Col>{placeToGo}</Col>
            </Row>
            <Row>
              <Col>{x.specialJobType}</Col>
              <Col>
                {this.props.getStatusBadge(
                  x.dispositionStatusText,
                  x.dispositionStatusColor
                )}
              </Col>
            </Row>

            {lastItemIndex !== index ? <hr /> : null}
          </React.Fragment>
        );
      });
    }

    return (
      <div className="text-center m-2">
        {t("vehicle_table:no_jobs_available")}
      </div>
    );
  }

  private getItems(): React.ReactNode {
    return this.filterItems().map((x) => {
      return (
        <Accordion.Item key={x.id} eventKey={x.id.toString()}>
          <Accordion.Header>
            <Container>
              <Row>
                <Col>{x.callSign}</Col>
                <Col>
                  {this.props.getStatusBadge(
                    x.dispositionStatusText,
                    x.dispositionStatusColor
                  )}
                </Col>
              </Row>
            </Container>
          </Accordion.Header>
          <Accordion.Body style={{ fontSize: "90%" }}>
            <Container>{this.getExtendedJobs(x.jobs)}</Container>
          </Accordion.Body>
        </Accordion.Item>
      );
    });
  }

  public render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Container fluid={true} className="mb-2 mt-3">
          <label
            className="search-label"
            htmlFor="search-bar-0"
            style={{ width: "100%" }}
          >
            <span className="sr-only">{t("vehicle_table:search")}</span>
            <input
              id="search-bar-0"
              type="text"
              autoComplete="off"
              name="searchString"
              value={this.state.searchString}
              onChange={(e: any) => this.onSearchStringChanged(e)}
              aria-label={t("vehicle_table:search")}
              className="form-control"
              placeholder={t("vehicle_table:search")}
            />
          </label>
        </Container>
        <Accordion>{this.getItems()}</Accordion>
      </React.Fragment>
    );
  }
}

export default withTranslation()(VehicleMobileTable);
